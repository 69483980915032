@font-face {
    font-family: 'helvetica_neue_lt_std97BlkCn';
    src: url('../fonts/helveticaneueltstd-blkcn.woff2') format('woff2'),
         url('../fonts/helveticaneueltstd-blkcn.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'helvetica_neue_lt_st97BlkCnOb';
    src: url('../fonts/helveticaneueltstd-blkcno.woff2') format('woff2'),
         url('../fonts/helveticaneueltstd-blkcno.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'helvetica_neue_lt_std57Cn';
    src: url('../fonts/helveticaneueltstd-cn.woff2') format('woff2'),
         url('../fonts/helveticaneueltstd-cn.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'helvetica_neue_lt_std57CnOb';
    src: url('../fonts/helveticaneueltstd-cno.woff2') format('woff2'),
         url('../fonts/helveticaneueltstd-cno.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'helvetica_neue_lt_std47LtCn';
    src: url('../fonts/helveticaneueltstd-ltcn.woff2') format('woff2'),
         url('../fonts/helveticaneueltstd-ltcn.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'helvetica_neue_lt_std47LtCnOb';
    src: url('../fonts/helveticaneueltstd-ltcno.woff2') format('woff2'),
         url('../fonts/helveticaneueltstd-ltcno.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'helvetica_neue_lt_std67MdCn';
    src: url('../fonts/helveticaneueltstd-mdcn.woff2') format('woff2'),
         url('../fonts/helveticaneueltstd-mdcn.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'helvetica_neue_lt_std67MdCnOb';
    src: url('../fonts/helveticaneueltstd-mdcno.woff2') format('woff2'),
         url('../fonts/helveticaneueltstd-mdcno.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'helvetica_neue_lt_std37ThCn';
    src: url('../fonts/helveticaneueltstd-thcn.woff2') format('woff2'),
         url('../fonts/helveticaneueltstd-thcn.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'helvetica_neue_lt_std37ThCnOb';
    src: url('../fonts/helveticaneueltstd-thcno.woff2') format('woff2'),
         url('../fonts/helveticaneueltstd-thcno.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'helvetica_neue_lt_27ultraLtCn';
    src: url('../fonts/helveticaneueltstd-ultltcn.woff2') format('woff2'),
         url('../fonts/helveticaneueltstd-ultltcn.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'helvetica_neue_l27ultraLtCnOb';
    src: url('../fonts/helveticaneueltstd-ultltcno.woff2') format('woff2'),
         url('../fonts/helveticaneueltstd-ultltcno.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

body {
	font-family: 'Inter', sans-serif;
	font-size: 13px;
	color: #555;
}
body .adminview, .adminview input, .adminview textarea, .adminview pre {
	font-family: 'helvetica_neue_lt_std57Cn';
	color: #222;
	font-size: 14px;
  letter-spacing: 0.3px;
}
body .adminview {
	-webkit-text-stroke: 0px;
	letter-spacing: 0.3px;
}
a {
	color: #01bd9c;
}
a:hover {
	color: #ffbf00;
}
h5 {
	font-size: 14px;
	font-weight: 400;
	line-height: 1.5;
}
h2 {
	font-size: 18px;
	font-weight: 400;
	line-height: 1.5;
}
i {
  font-style: normal;
}
b, strong {
	font-weight: normal;
}
.content-wrapper {
	background-color: #eff2f7;
}
.logo-xl.brand-image-xs {
	left: 55px;
}
.login-page, .register-page {
	background: #eff2f7;
}
.login-box-msg, .register-box-msg {
	color: #25354c;
}
.page-item .page-link {
	color: #01bd9c;
	box-shadow: none;
}
.page-item.active .page-link {
	background-color: #01bd9c;
	border-color: #01bd9c;
}
.form-control:focus {
	border-color: #01bd9c;
}
.card.card-secondary {
	margin-bottom: 10px;
	border-top: 3px solid #01bd9c;
	border-left: none;
	border-right: none;
	border-bottom: none;
	box-shadow: 0 1px 1px rgba(0,0,0,.1);
	border-radius: 0px;
}
.card.card-secondary > .card-header {
	padding-right: 30px;
	position: relative;
	background-color: #fff;
	color: #444;
	border-bottom: 1px solid #f4f4f4;
}
.card.card-secondary > .card-header div {
	font-size: 15px;
	font-weight: normal;
	line-height: normal;
}
.card.card-secondary > .card-header .card-title i {
	padding-right: 6px;
}
.form-horizontal .label-wrapper {
	float: right;
	display: flex;
	align-items: flex-start;
}
.required {
	color: red;
}
.content-header h1 > small {
	color: #01bd9c;
	font-weight: 400;
	font-size: 65%;
	margin-left: 15px;
}
.content-header h1 > small i {
	font-size: .8em;
	padding-right: 2px;
}
.btn {
	font-size: 13px;
	font-weight: normal;
}
.btn-primary {
	color: #fff;
	background-color: #01bd9c;
	border-color: #01bd9c;
}
.btn-primary:hover, .btn-primary.focus, .btn-primary:focus, .btn-primary:not(:disabled):not(.disabled):active {
	color: #fff;
	background-color: #1db3a4;
	border-color: #1db3a4;
	box-shadow: none;
	outline: none;
}
.btn-link {
	color: #01bd9c;
}
.btn-link:hover {
	color: #01bd9c;
	opacity: 0.8;
}
.content-header .btn {
	margin-left: 5px;
}
.fa, .far, .fas {
	padding-right: 3px;
	font-size: .9em;
}
.col-form-label {
	font-size: 13px;
	text-align: right;
}
label:not(.form-check-label):not(.custom-file-label) {
	font-weight: normal;
	line-height: normal;
}
.form-group {
	margin-bottom: 15px;
}
.form-control {
	border-radius: 0;
	height: 34px;
	font-size: 13px;
}
.input-group-append .btn, .input-group-prepend .btn {
	border-radius: 0;
	background-color: #01bd9c;
	border-color: #01bd9c;
	font-size: 13px;
	font-weight: 500;
}
.login-card-body .input-group .form-control:focus ~ .input-group-append .input-group-text, .login-card-body .input-group .form-control:focus ~ .input-group-prepend .input-group-text, .register-card-body .input-group .form-control:focus ~ .input-group-append .input-group-text, .register-card-body .input-group .form-control:focus ~ .input-group-prepend .input-group-text {
	border-color: #01bd9c;
}
.modal-title {
	font-size: 16px;
	line-height: normal;
	font-weight: 500;
}
.modal-header {
	padding: 10px 15px;
}
.table td .fas {
	padding-right: 10px;
}
[class*="sidebar-dark-"], .layout-navbar-fixed .wrapper .sidebar-dark-primary .brand-link:not([class*="navbar"]) {
 background-color: #292d32;
}
[class*="sidebar-dark"] .brand-link {
	border-bottom: 1px solid #34383D;
}
.card-primary:not(.card-outline) > .card-header {
	background-color: #01bd9c;
	border-color: #01bd9c;
}
.small-box {
	border-radius: 0;
	box-shadow: none;
}
.layout-fixed .main-sidebar.elevation-1 {
	border: none;
	box-shadow: 0 1px 1px rgba(0,0,0,.12), 0 1px 2px rgba(0, 0, 0, 0.11) !important;
}
[class*="sidebar-light-"] .nav-sidebar > .nav-item:hover > .nav-link, [class*="sidebar-light-"] .nav-sidebar > .nav-item.menu-open > .nav-link, [class*="sidebar-light-"] .nav-sidebar > .nav-item:hover > .nav-link, [class*="sidebar-light-"] .nav-treeview > .nav-item > .nav-link:hover, [class*="sidebar-light-"] .nav-treeview > .nav-item > .nav-link:focus, [class*="sidebar-light-"] .nav-treeview > .nav-item > .nav-link:hover, [class*="sidebar-light-"] .nav-treeview > .nav-item > .nav-link.active, [class*="sidebar-light-"] .nav-treeview > .nav-item > .nav-link.active:hover {
	background-color: rgba(175, 161, 161, 0.08);
	color: #212529;
}
.layout-navbar-fixed.layout-fixed .wrapper .sidebar {
	padding: 0 20px;
	position: static;
	background: none;
	width: 100%;
}
.nav-sidebar .nav-treeview > .nav-item > .nav-link > .nav-icon {
	font-size: 13px;
	vertical-align: middle;
}
.nav-sidebar > .nav-item > .nav-link {
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #aaa;
	padding: 11px 0px;
}
.nav-sidebar > .nav-item {
	position: relative;
	width: 100%;
	margin: 0px;
	padding: 0;
}
.nav > li a.add-new-link {
	position: absolute;
	right: 0;
	top: 50%;
	font-size: 11px;
	background: #01bd9c !important;
	color: #fff !important;
	border-radius: 3px;
	width: 18px;
	text-align: center;
	height: 18px;
	margin-top: -9px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.nav > li a.add-new-link i {
	margin: 0;
	padding: 0;
	line-height: normal;
	color: #fff;
}
.nav-sidebar .nav-treeview > .nav-item > .nav-link {
	border: none;
	border-radius: 0;
}
.sidebar-dark-primary .nav-sidebar > .nav-item > .nav-link.active, .sidebar-light-primary .nav-sidebar > .nav-item > .nav-link.active {
	background: none;
	color: #aaa;
	box-shadow: none;
}
[class*="sidebar-dark-"] .nav-sidebar > .nav-item.menu-open > .nav-link, [class*="sidebar-dark-"] .nav-sidebar > .nav-item:hover > .nav-link, [class*="sidebar-dark-"] .nav-sidebar > .nav-item > .nav-link:focus {
	background: none;
	color: #fff;
}
.content-header h1 {
	font-size: 20px;
	font-weight: 500;
	color: #25354c;
}
.table-bordered thead td, .table-bordered thead th, .table thead th {
	border-bottom-width: 1px;
	color: #25354c;
	font-weight: normal;
}
table.dataTable {
	border-top: none;
}
.table-striped tbody tr:nth-of-type(2n+1) {
	background-color: rgba(0,0,0,.02);
}
[class*="sidebar-light-"] .sidebar a {
	color: #25354c;
}
.card-header {
	color: #25354c;
}
.navbar-light .navbar-nav .nav-link i {
	font-size: 14px;
	margin: 0;
}
/*.sidebar-light-primary .nav-sidebar > .nav-item .nav-icon {
  opacity: 0.3;
  vertical-align: text-bottom;
  color: #2a3a50;
}
.nav-sidebar > .nav-item .nav-link.active .nav-icon {
  color: #fff;
  opacity: 0.5;
}*/
.nav-sidebar > .nav-item .nav-treeview .nav-link.active .nav-icon {
	color: #2a3a50;
}
.nav-tabs.flex-column .nav-link {
	color: #555;
	font-weight: normal;
	font-size: 13.5px;
	margin-bottom: 8px;
	background: #fdfdfd;
}
.nav-tabs.flex-column .nav-item.show .nav-link, .nav-tabs.flex-column .nav-link.active {
	color: #01bd9c;
	border-color: #dee2e6;
	border-radius: 0px;
	background: #fff;
}
.nav-tabs.flex-column {
	border: none;
  width: auto;
  margin: 0;
}
.nav-tabs.flex-column .nav-link:focus, .nav-tabs.flex-column .nav-link:hover {
	border-color: transparent;
	color: #01bd9c;
}
.nav-tabs.flex-column .nav-link, .nav-tabs.flex-column .nav-link:focus, .nav-tabs.flex-column .nav-link:hover {
	border: 1px solid #dee2e6;
	border-radius: 0;
}
#vert-tabs-tabContent, #vert-tabs-tabContent.tab-content > .tab-pane, #vert-tabs-tabContent .card.card-secondary {
	height: 100%;
}
#vert-tabs-tab.nav-tabs.flex-column .nav-item.show .nav-link, .nav-tabs.flex-column .nav-link.active::after {
	content: "\f105";
	font-weight: 900;
	font-family: "Font Awesome 6 Free";
	float: right;
	position: relative;
	right: -5px;
}
.small-box {
	background: #01bd9c;
}
.small-box > .inner {
	color: #fff;
}
table.dataTable td .badge,.edit-app-status {
	background: none !important;
	border: 1px solid #01bd9c;
	color: #666 !important;
	font-weight: 500;
}
.edit-app-status
{
	font-size: 14px;
    padding: 8px;
 
}
.table thead th{
	white-space: nowrap;
}
.table thead th i.fa {
	font-size: 10px;
	margin-left: 5px;
  }
.main-header .navbar-nav .nav-item.open .dropdown-menu {
	display: block;
}
.main-header .navbar-nav .dropdown-item {
	font-size: 13px;
	padding: 8px 15px;
}
.main-header .dropdown-menu {
	border: 1px solid #ddd;
	margin-top: 8px;
	border-radius: 0;
	padding: 0;
}
.main-header.navbar .dropdown-divider {
	margin: 0;
}
.navbar-light .navbar-nav .nav-link small i {
	font-size: 10px;
}
.dropdown-item.active, .dropdown-item:active {
	background-color: #01bd9c;
}
.fade:not(.show) {
	opacity: 1;
}
.input-group > .form-control {
	background: #fff;
}
.modal-dialog.form-dialog {
	max-width: 900px;
}
.sidebar-light-primary .nav-sidebar > .nav-item > .nav-link.active ~ .nav.nav-treeview {
	display: block;
}
.header-top {
	background: #fdb528;
}
.header-top .navbar-dark {
	background: none;
	padding: 0;
}
.header-top .navbar-nav .nav-link {
	padding: 10px 5px;
	color: #fff;
}
.header-middle {
	padding: 20px 0;
	box-shadow: 0 1px 10px rgba(50,50,50,.2);
	background: #fff;
}
.brand-logo {
	max-width: 220px;
}
.web-header-title {
	font-size: 15px;
	font-weight: normal;
	color: #25bcb5;
}
.footer-main {
	padding: 18px 0;
}
.footer-main p {
	margin: 0px
}
.web-page-content {
	min-height: calc(100vh - 194px);
}
.web-page-inner {
	padding: 25px 0;
}
.web-version .card-primary:not(.card-outline) > .card-header {
	background-color: #fff;
	border-color: rgba(0,0,0,.125);
	color: #25bcb5;
}
.web-version .card-primary:not(.card-outline) > .card-header .card-title {
	font-weight: 500;
}
.web-version {
	background: #f5f6f6;
}
.progressbar {
	margin-bottom: 20px;
	overflow: hidden;
	color: #a8abb5;
	display: flex;
}
.progressbar .active, .progressbar li a {
	color: #26bcb5;
}
.progressbar li {
	list-style-type: none;
	font-size: 13px;
	position: relative;
	font-weight: 400;
	text-align: center;
	width: 100%;
	line-height: normal;
}
.progressbar li strong {
	font-weight: 500;
	font-size: 12px;
}
.progressbar li span {
	width: 30px;
	height: 30px;
	line-height: 27px;
	display: block;
	font-size: 13px;
	color: #a8aab5;
	background: #fff;
	border: 2px solid #eee;
	border-radius: 50%;
	margin: 0 auto 5px auto;
	padding: 0;
	font-weight: 700;
	position: relative;
	z-index: 2;
}
.progressbar li.active span {
	background: #26bcb5;
	border-color: #26bcb5;
	color: #fff;
}
.progressbar li:after {
	content: '';
	width: 100%;
	height: 2px;
	background: #eee;
	position: absolute;
	left: 50%;
	top: 13px;
	z-index: 1;
}
.progressbar li:last-child:after {
	display: none;
}
.progressbar li.active:before {
	background: #26bcb5;
}
.progressbar li.active:after {
	background: #26bcb5;
}
.steps-content .btn-primary {
	margin: 0 10px;
}
.web-version .btn-primary {
	color: #fff;
	background-color: #26bcb5;
	border-color: #26bcb5;
}
.web-version .btn-primary:not(:disabled):not(.disabled).active, .web-version .btn-primary:not(:disabled):not(.disabled):active, .web-version .show > .btn-primary.dropdown-toggle {
	color: #fff;
	background-color: #26bcb5;
	border-color: #26bcb5;
}
.top-alert {
	margin-top: 10px;
}
.signature {
	display: block;
	margin-bottom: 10px;
	border: 1px solid black;
	width: 320px;
	height: 200px;
}
.nav-sidebar > .nav-item .nav-icon {
	font-size: 16px !important;
	width: 24px;
	margin-right: 5px;
}
.layout-navbar-fixed .wrapper .brand-link, .main-sidebar, .main-sidebar::before, .layout-navbar-fixed .wrapper .main-sidebar:hover .brand-link {
	width: 230px;
}
.sidebar-mini .main-sidebar .nav-link, .sidebar-mini-md .main-sidebar .nav-link, .sidebar-mini-xs .main-sidebar .nav-link {
	width: auto;
}
.sidebar-mini .main-sidebar .nav-child-indent .nav-treeview .nav-link, .sidebar-mini-md .main-sidebar .nav-child-indent .nav-treeview .nav-link, .sidebar-mini-xs .main-sidebar .nav-child-indent .nav-treeview .nav-link {
 width: calc(230px - .5rem * 2 - 1rem);
}
.flex-grid {
	display: flex;
	flex: 1;
	flex-direction: row;
	height: 100%;
	margin: 0 auto;
	width: 100%;
	padding: 0px;
}
.flex-column {
	display: flex;
	flex-direction: column;
	flex: 1;
	margin: 0 13px;
	width: 0;
}
.flex-column.flex-2 {
	flex: 2;
}
.position.card {
	background: none;
	box-shadow: none;
	margin: 0;
}
.home .candidates-content {
	background: #fff;
}
 @media (min-width: 768px) {
body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .content-wrapper, body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-footer, body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-header {
	transition: margin-left .3s ease-in-out;
	margin-left: 230px;
}
}
.poss-page-header {
	padding: 20px 0;
	margin-bottom: 0px;
}
.home-header {
	display: flex;
	justify-content: space-between;
}
.poss-page-header .metrics {
	align-items: center;
	display: flex;
	list-style: none;
	margin: 0;
}
.home-header-title h1 {
	font-size: 22px;
	font-weight: 400;
	line-height: 1;
	margin-bottom: 0;
}
.metrics li.metric-label h2 {
	display: inline-block;
	position: relative;
	font-size: 24px;
	line-height: 36px;
	margin-bottom: 0;
}
.metrics-label h2 {
	font-weight: 400;
	margin-bottom: 0;
}
.metrics li.metric-label {
	margin: 0 5px 0 30px;
}
.metrics li p {
	text-align: left;
	margin-left: 5px;
	display: inline-block;
	font-size: 12px;
	line-height: 1.3;
	color: #84929f;
	margin-bottom: 0;
}
.candidate-list .candidate .candidate-header h3 {
	font-size: 16px;
	color: #222;
	line-height: 20px;
	margin: 0 20px 0 0;
}
.card-box.position {
	border: 1px solid #dedede;
	border-radius: 4px;
	margin-bottom: 15px;
	background: #fff;
	padding: 15px 10px;
	position: relative;
	transition: .3s ease;
}
ul.positions {
	margin: 0;
	padding: 0;
}
ul.list.candidate-list {
	margin: 0;
	padding: 0;
}
ul.list.candidate-list .card-box 
{
cursor: pointer;
}
.adminview .flex-header {
	padding: 15px;
}
.adminview .flex-scrollable-widget .flex-scroll {
	flex: 1;
	overflow: auto;
	padding: 0 15px 20px;
}
.adminview .card-box.candidate {
	padding: 10px;
	border: 1px solid #dedede;
}
.card-box.candidate p {
	margin: 0;
	color: #68747e;
}
.flex-scrollable-widget.candidates-content {
	border: 1px solid #dedede;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	width: 100%;
	max-height: 850px;
}
.avatar {
	border-radius: 100%;
	border: none;
	display: inline-block;
	height: 40px;
	vertical-align: middle;
	width: 40px;
}
.avatar[initial] {
	position: relative;
	text-align: center;
}
.avatar.sm {
	width: 30px;
	height: 30px;
	display: inline-block;
}
.avatar.color2 {
	background-color: #8357c1;
}
.avatar.color3 {
	background-color: #8357c1;
}
.avatar.color4 {
	background-color: #8357c1;
}
.avatar.color5 {
	background-color: #8357c1;
}
.main .candidate-list .candidate .avatar, .flex-scrollable-widget.candidates-content .candidate-list .candidate .avatar, .recently-viewed .candidate-list .candidate .avatar {
	float: left;
	border-radius: 20px;
}
.main .candidate-list .candidate .candidate-info, .flex-scrollable-widget.candidates-content .candidate-list .candidate .candidate-info, .recently-viewed .candidate-list .candidate .candidate-info {
	margin-left: 40px;
}
.main .candidate-list .candidate .candidate-body p, .flex-scrollable-widget.candidates-content .candidate-list .candidate .candidate-body p, .recently-viewed .candidate-list .candidate .candidate-body p {
	font-size: 12px;
	color: #68747e;
	line-height: 18px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.avatar[initial]::before {
	color: rgba(255, 255, 255, 0.9) !important;
	content: attr(initial) !important;
	font-size: 20px;
	font-weight: 200;
	height: 100%;
	left: 0;
	line-height: 37px;
	position: absolute;
	text-align: center;
	text-transform: uppercase;
	top: 0;
	width: 100%;
	z-index: 0;
}
.avatar.sm[initial]::before {
	font-size: 12px;
	line-height: 28px;
}
.card.small.still {
	box-shadow: none;
}
.flex-column.flex-2 .position.card .card-box h3 {
	font-size: 16px;
	line-height: 1.5;
	margin-bottom: 4px;
}
.fa-circle.published {
	color: #76d691 !important;
}
.flex-column.flex-2 .position.card .card-box h3 i {
	font-size: 14px;
	line-height: 1.7;
}
.positions-content .position .position-footer ul li {
	display: inline-block;
	font-size: 12px;
}
.positions-content .position .position-footer .statuses li {
	margin-left: 10px;
}
.flex-column.flex-2 .position.card .card-box .position-footer .statuses li {
	font-size: 14px;
}
.positions-content .position .position-footer .statuses {
	float: right;
}
.position.card .card-box h3 span {
	margin-left: 4px;
}
.positions-content .position .position-secondary-body {
	float: left;
	font-size: 14px;
	color: #666;
}
.team .member {
	height: 18px;
	margin-right: 2px;
	width: 18px;
	vertical-align: middle;
	line-height: 16px;
	text-align: center;
	border: 1px solid #edeff0;
	border-radius: 100%;
	display: inline-block;
}
.team .member.add {
	background-color: #fff;
	color: #84929f;
	cursor: pointer;
	font-size: 14px;
}
.team .member {
	height: 18px;
	margin-right: 2px;
	width: 18px;
	vertical-align: middle;
}
.team .avatar {
	height: 18px;
	margin-right: 2px;
	width: 18px;
	vertical-align: middle;
}
.team .avatar[initial]::before {
	font-size: 9px;
	line-height: 16px;
}
.positions-content .position .position-body p {
	color: #666;
	font-size: 12px;
	margin-bottom: 6px;
}
.flex-column.flex-2 .position.card .card-box .position-body p {
	font-size: 14px;
}
.flex-column.flex-2 .position.card .card-box .position-footer .statuses li a {
	color: #333;
}
.flex-column.align-positions {
	margin-top: 66px;
}
.color-bars {
	list-style: none;
	margin: 0;
	padding: 0;
}
.home.content-wrapper .flex-column {
	margin-left: 0;
	margin-right: 0;
}
.btn-close::before {
	content: 'X';
	visibility: visible;
	color: "black";
	font-weight: bold;
}
.btn-close {
	/*background-color: red; */
  
	position: absolute;
	right: 15px;
	top: 10px;
	width: 20px;
	height: 20px;
	background: none;
	border: none;
}
.main-header .nav-link{
  cursor: pointer;
}
ul.head-tabs {
  margin: 0;
  padding: 0;
}
.head-tabs .tab-item {
  display: inline;
  margin-left: 20px;
  transition: .3s;
  font-size: 14px;
}
.head-tabs .tab-item a{
  color: #222;
}
.head-tabs .tab-item a.active {
  border-bottom: 4px solid #01bd9c;
  padding-bottom: 5px;
}
.pipeline-item h2 {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 10px 15px;
	margin: 0;
	min-height: 52px;
	font-size: 16px;
  }
  .pipeline-item h2 i {
	padding-right: 8px;
	font-size: 16px;
  }
  .pipeline-item h2 .candidates-total {
	margin-left: 6px;
	background: #ddd;
	border-radius: 10px;
	padding: 2px 5px;
	color: #888;
	font-size: 11px;
	height: 18px;
	width: 18px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
  }
  .pipeline-item .candidate-info {
	width: 100%;
	padding-left: 10px;
  }
  .pipeline-item .candidate-container {
	display: flex;
	width: 100%;
  }
  .pipeline-item .candidate-header h3 {
	color: #222;
	font-size: 15px;
	line-height: 18px;
	margin-bottom: 4px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
  }
  .pipeline-item .candidate-header .headline {
	color: #68747e;
	font-size: 12px;
  }
  .pagination span {
	margin-left: 15px;
  }
  .table-responsive .pagination {
	justify-content: end;
  }
  .table-responsive .form-select {
	background: #fff;
	border: 1px solid #ced4da;
	margin-left: 10px;
  }
  .pipeline-view .content-wrapper{
	background: #fff;
  }
  .pipeline-view .content-wrapper .card{
	border: none;
	box-shadow: none;
  }
  .pipeline-view .content-wrapper .card-body {
	padding: 0;
  }
  .tbl_header_created_at,
  .tbl_header_updated_at {
	text-align: center;
  }
  .pipeline-item {
	border-radius: 4px;
  }
  .form-group p.text-danger {
	margin-bottom: 0;
	font-size: 12px;
	margin-top: 5px;
	line-height: normal;
  }
  .css-13cymwt-control {
	border-radius: 0;
	border-color: #ced4da;
  }
  .css-13cymwt-control:hover{
	border-color: #ced4da;
  }
  .content-wrapper.content-applicant {
	background: #f9fafa;
  }
  .content-applicant .nav-tabs .nav-link {
	padding: 0;
	margin: 5px 14px 0px 0px;
	background: none;
	border: none;
	color: #222;
	font-size: 14px;
  }
  .content-applicant .nav-tabs .nav-link.active{
	border-bottom: 4px solid #01bd9c;
	padding-bottom: 5px;
  }
  .content-applicant .nav-tabs {
	margin-bottom: 10px;
	border: none;
	margin-top: 10px;
  }
  .content-applicant .card.card-secondary {
	border-top: none;
	box-shadow: none;
	margin-bottom: 0;
  }
  .content-applicant .form-horizontal {
	background: #fff;
	padding: 0px 16px;
	margin: 0 -16px;
	border-top: 1px solid #dedede;
  }
  .content-applicant .card.card-secondary > .card-header {
	border: none;
	padding-left: 0;
  }
  .content-applicant .card.card-secondary .card-body {
	border: 1px solid #edeff0;
  }
  .content-wrapper.content-applicant .content-header small .btn, 
  .content-wrapper.content-applicant .content-header small {
	padding: 0;
	border: none;
	vertical-align: middle;
	display: inline-block;
  }
  .content-wrapper.content-applicant .candidate-color-bar-container {
	display: inline-block;
	vertical-align: middle;
	margin-right: 6px;
  }
  .content-wrapper.content-applicant .content-header .btn-cancel-link {
	border: 1px solid #222;
	margin-left: 15px;
	line-height: 27px;
	text-align: center;
	height: 30px;
	width: 30px;
	border-radius: 50%;
	font-size: 16px;
	padding: 0;
	color: #222;
  }
  .content-wrapper.content-applicant .content-header .btn-cancel-link i {
	padding: 0;
  }
  .candidate-fields .form-group {
	margin-bottom: 8px;
  }
  .form-horizontal .candidate-fields .label-wrapper {
	float: left;
  }
  .form-horizontal .candidate-fields .form-control {
	height: 28px;
	border: 1px solid #eee;
	padding: 5px;
	color: #333;
	font-size: 13px;
  }
  .form-horizontal .candidate-fields label {
	font-size: 13px;
  }
  .form-horizontal .candidate-fields .input-group-append .btn {
	height: 28px;
	line-height: normal;
  }
  .form-horizontal .candidate-fields .label-wrapper label {
	padding-bottom: 5px;
	padding-top: 5px;
  }
  .content-applicant .card.card-secondary.candidate-fields {
	padding: 15px;
	background: #f9fafa;
	border-left: 1px solid #dedede;
	margin-right: -16px;
	height: 100%;
  }
  .content-applicant .card.card-secondary.candidate-fields .card-header {
	background: none;
	padding-top: 0;
  }
  .content-applicant .card.card-secondary.candidate-fields .card-body {
	background: #fff;
	box-shadow: 0 1px 1px rgba(0,0,0,.1);
  }
  .form-horizontal .candidate-fields .form-control.textarea {
	height: 80px;
  }

  .custom-dialog
  {
    max-width: 800px !important;
  }
.react-datepicker-wrapper {
  width: 100%;
}
.modal-dialog.custom-dialog {
  font-family: 'helvetica_neue_lt_std57Cn';
  font-size: 14px;
}

.scorecard-switch {
	border-radius: 4px;
	display: inline-block;
	position: relative;
	vertical-align: middle;
	white-space: nowrap;
	width: 100%;
}
.scorecard-switch label {
	border-radius: 3px;
	display: inline-block;
	width: 20%;
}
.scorecard-switch label.neutral {
	width: 8%;
}
.scorecard-switch label input {
	display: none !important;
}
.scorecard-switch label span.scorecard-option {
	border: 1px solid #dedede;
	background-color: #f9fafa;
	padding: 5px 10px;
	cursor: pointer;
	display: inline-block;
	font-size: 12px;
	line-height: 1.5;
	transition: .3s ease;
	text-align: center;
	width: 100%;
	border-left: none;
}
.scorecard-switch label:first-child span.scorecard-option {
	border-radius: 4px 0 0 4px;
	border-left: 1px solid #dedede;
}
.scorecard-switch label:last-child span.scorecard-option {
	border-radius: 0 4px 4px 0;
}
.scorecard-switch label span.scorecard-option span.scorecard-name{
	font-size: 12px;
	margin-left: 0px;
}
.scorecard-switch label input:checked ~ span.scorecard-option.neutral, .scorecard-switch-list label  span.scorecard-option.neutral {
	background-color: #f5d44f;
	border-color: #f5d44f;
	color: #fff;
}
.scorecard-switch label input:checked ~ span.scorecard-option.poor,.scorecard-switch-list label span.scorecard-option.poor {
	background-color: #e15258;
	border-color: #e15258;
	color: #fff;
  }
  .scorecard-switch label input:checked ~ span.scorecard-option.poor {
	background-color: #e15258;
	border-color: #e15258;
	color: #fff;
  }
  .scorecard-switch label input:checked ~ span.scorecard-option.good,.scorecard-switch-list label span.scorecard-option.good {
	background-color: #76d691;
	border-color: #76d691;
	color: #fff;
  }
  .scorecard-switch label input:checked ~ span.scorecard-option.good {
	background-color: #76d691;
	border-color: #76d691;
	color: #fff;
  }
.scorecard-switch i {
	padding-right: 0;
}

.scorecard-switch-list label
{
	width: auto !important;
}


.scorecard-switch-list  .scorecard-option
{
	border-radius: 4px !important;
}
.raw {
  max-height: 200px;
  overflow: auto;
}
.width_200{
	min-width: 200px;
}

.ql-editor
{
    color: #222 !important;
    font-family: 'helvetica_neue_lt_std57Cn' !important;
    font-size: 16px !important;
    letter-spacing: .3px !important;
}

.ql-editor p
{
    margin-bottom:1em !important;
}
